import { useState, useEffect } from "react";

export function useSyncedLocalStorage<T>(key: string, initialValue: T): [T, (newValue: T) => void, () => void] {
  const [value, setValue] = useState<T>(() => {
    // We check if there's a value in state already (if it has been set in the current tab)
    const storedValue = typeof window !== "undefined" ? localStorage.getItem(key) : null;

    // Only initialize from localStorage if the value has not been set yet
    if (storedValue) {
      try {
        return JSON.parse(storedValue) as T;
      } catch (error) {
        console.error(`Error parsing localStorage key "${key}":`, error);

        // if there's an error parsing the value, remove it from localStorage
        if (typeof window !== "undefined") localStorage.removeItem(key);
        return initialValue;
      }
    }
    return initialValue;
  });

  // Only run this effect once on mount (empty dependency array)
  useEffect(() => {
    const storedValue = typeof window !== "undefined" ? localStorage.getItem(key) : null;

    if (storedValue !== null) {
      try {
        setValue(JSON.parse(storedValue) as T);
      } catch (error) {
        console.error(`Error parsing sessionStorage key "${key}":`, error);
      }
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        try {
          // Only update the state if the value is different (avoid resetting to null)
          setValue(event.newValue ? (JSON.parse(event.newValue) as T) : value);
        } catch (error) {
          console.error(`Error parsing updated localStorage key "${key}":`, error);
        }
      }
    };

    if (typeof window !== "undefined") window.addEventListener("storage", handleStorageChange);
    return () => {
      if (typeof window !== "undefined") window.removeEventListener("storage", handleStorageChange);
    };
  }, [key, initialValue]);

  const setStoredValue = (newValue: T) => {
    setValue(newValue);
    if (typeof window !== "undefined") localStorage.setItem(key, JSON.stringify(newValue));
  };

  const clear = () => {
    setValue(initialValue);
    if (typeof window !== "undefined") localStorage.removeItem(key);

    // Optionally, you can trigger the 'storage' event to notify other tabs of the change
    if (typeof window !== "undefined") window.dispatchEvent(new StorageEvent("storage", { key, newValue: null }));
  };

  return [value, setStoredValue, clear];
}
