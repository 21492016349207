import React, { ReactElement, createContext, useContext, useMemo, useState, useEffect } from "react";
import { CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import LoginModal from "@components/UserModals/LoginModal";
import { useSyncedLocalStorage } from "@contexts/useSyncedLocalStorage";
import { IUser, RegistrationData } from "../../types/User";
import { getDbUser } from "./functions/getDbUser";
import { gacRegister } from "./functions/gacRegister";
import { setRegistrationData } from "./functions/setRegistrationData";
import { getRegistrationData } from "./functions/getRegistrationData";
import { getInternalToken } from "./functions/getInternalToken";
import { getEmailFromCurrentUser } from "./functions/getEmailFromCurrentUser";

import { login as cognitoLogin } from "./functions/login";
import { logout as cognitoLogout } from "./functions/logout";

type AuthContextType = {
  getRegistrationData: () => RegistrationData | null;
  getCognitoUserPool: () => Promise<CognitoUserPool>;
  getCognitoUser: () => Promise<CognitoUser | null>;

  setGacUser: (user: IUser | null) => void;
  gacUser: IUser | null;
  getAuthToken: () => Promise<string | null>;

  getUserRoles: () => Promise<string[] | null>;
  login: (email: string, password: string) => Promise<IUser | null>;
  logout: () => Promise<void>;
  loadUser: () => Promise<IUser | null>;
  ShowLoginModal: (tab: "login" | "new-account" | "forgot-password") => void;
  showingLoginModal: boolean;
};

const AuthContext = createContext<AuthContextType>({
  getRegistrationData: () => null,
  getCognitoUser: async () => null,

  getCognitoUserPool: async () => ({}) as CognitoUserPool,

  setGacUser: () => null,
  gacUser: null,
  getAuthToken: async () => null,
  getUserRoles: async () => null,
  login: async () => null,
  logout: async () => Promise.resolve(),
  loadUser: async () => null,
  ShowLoginModal: (_tab) => null,
  showingLoginModal: false,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactElement | ReactElement[] }) => {
  // Using useSyncedLocalStorage to store user data in localStorage and sync it across tabs, and persist across sessions
  const [gacUser, setGacUserInternal] = useSyncedLocalStorage<IUser | null>("GACUSer", null);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [tokenExpire, setTokenExpire] = useState<number | null>(null);
  const [userRoles, setUserRoles] = useSyncedLocalStorage<string[] | undefined>("GACUserRoles", undefined);

  const [loginOpen, setLoginOpen] = useState(false);
  const [activeLoginModalTab, setActiveLoginModalTab] = useState<"login" | "new-account" | "forgot-password">("login");

  // useReducer(gacUserReducer, { isLoading: false });

  // const cognitoUser = gacUserPool.getCurrentUser();

  // Usage example

  const initializeGacUserPool = () =>
    new Promise<CognitoUserPool>((resolve, reject) => {
      try {
        if (!process.env.GATSBY_AWS_USER_CLIENT_ID) throw new Error("Missing key in env:  GATSBY_AWS_USER_CLIENT_ID ");
        if (!process.env.GATSBY_AWS_USER_POOL_ID) throw new Error("Missing key in env:  GATSBY_AWS_USER_POOL_ID ");

        const gacUserPool = new CognitoUserPool({
          UserPoolId: process.env.GATSBY_AWS_USER_POOL_ID,
          ClientId: process.env.GATSBY_AWS_USER_CLIENT_ID,
        });

        resolve(gacUserPool);
      } catch (error) {
        reject(error);
      }
    });

  const getCognitoUserPool = () => initializeGacUserPool().then((gacUserPool) => gacUserPool);

  const getCognitoUser = () => initializeGacUserPool().then((gacUserPool) => gacUserPool.getCurrentUser());

  const getUserRoles = async () => {
    // short circuit ... if user not logged in , return empty
    if (!gacUser) return [];

    // short circuit ... if userRoles already loaded, return it.
    if (userRoles) {
      return userRoles;
    }

    const cognitoUser = await getCognitoUser();

    if (!cognitoUser) {
      return [];
    }

    await new Promise<CognitoUserSession>((resolve, reject) => {
      cognitoUser.getSession((error: Error | null, session: CognitoUserSession) => {
        if (error) {
          reject(error);
          return;
        }

        resolve(session);
      });
    });

    return new Promise<string[]>((resolve, reject) => {
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
          return;
        }

        if (!attributes) {
          reject(new Error("No attributes found"));
          return;
        }

        const roles = attributes.filter((attr) => attr.Name.startsWith("custom:is")).map((attr) => attr.Name);
        resolve(roles);
      });
    })
      .then((roles) => {
        setUserRoles(roles);
        return roles;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  };

  const setGacUser = (user: IUser | null) => {
    setGacUserInternal(user);
    // if (!user && typeof window !== "undefined") {
    //   sessionStorage.removeItem("GACUser");
    //   return;
    // }

    // if (typeof window !== "undefined") sessionStorage.setItem("GACUser", JSON.stringify(user));
  };

  const getAuthToken = async () => {
    if (authToken && tokenExpire && Date.now() < tokenExpire - 5 * 60 * 1000) return authToken;

    const cognitoUser = await getCognitoUser();
    if (!cognitoUser) return null;

    const token = await getInternalToken(cognitoUser);
    if (!token) return null;

    setAuthToken(token.token);
    setTokenExpire(token.expire);

    return token.token;
  };

  const loadUser = async (): Promise<IUser | null> => {
    if (gacUser) return gacUser;

    const authtoken = await getAuthToken();

    // couldnt get authToken from cognito, user not logged in, ensure user is clear
    if (!authtoken) {
      setGacUser(null);
      return null;
    }

    const storedUser = typeof window !== "undefined" ? sessionStorage.getItem("GACUser") : null;

    // if user is stored in session, use that
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setGacUser(user);
      return user;
    }

    const registrationData = getRegistrationData();
    // if user is not stored in session, and no registration data, load user from db
    if (!registrationData) {
      const user = await getDbUser(authtoken);
      setGacUser(user);
      return user;
    }

    const tokenEmail = await getEmailFromCurrentUser(await getCognitoUser());

    // if registration data exists, update user with registration data
    if (registrationData && registrationData.email === tokenEmail) {
      const tempUser = await gacRegister(authtoken, registrationData);
      setGacUser(tempUser);

      setRegistrationData(null);
      return tempUser;
    }

    // If registration data exists but for different email, skip registration data update, and just load the user from db
    // This would rarely happen.
    const tempUser = await getDbUser(authtoken);
    setGacUser(tempUser);
    return tempUser;
  };

  const login = async (email: string, password: string): Promise<IUser | null> =>
    cognitoLogin(getCognitoUserPool, { email, password }).then(() => loadUser());

  const logout = async () => {
    setGacUser(null);
    setAuthToken(null);
    setTokenExpire(null);
    setUserRoles(undefined);

    return getCognitoUser().then((cognitoUser) => {
      cognitoLogout(cognitoUser).then(() => {
        if (typeof window !== "undefined") {
          localStorage.removeItem("GACTokenExpire");
          localStorage.removeItem("GACToken");
        }
      });
    });
  };

  // load user on mount
  useEffect(() => {
    const loadUserAsync = async () => {
      await loadUser();
    };

    loadUserAsync();
  }, []); // Removed dependencies to ensure it runs only once on mount

  const value = useMemo(
    () => ({
      getRegistrationData,
      getCognitoUserPool,
      getCognitoUser,

      setGacUser: (user: IUser | null) => setGacUser(user),
      gacUser,
      getAuthToken,
      getUserRoles,
      loadUser,
      login,
      logout,
      // eslint-disable-next-line default-param-last
      ShowLoginModal: async (tab: "login" | "new-account" | "forgot-password" = "login") => {
        // set active tab in login modal
        setActiveLoginModalTab(tab);

        // open the modal
        setLoginOpen(true);
      },
      showingLoginModal: loginOpen,
    }),
    [gacUser, loginOpen, activeLoginModalTab, userRoles, authToken, tokenExpire, logout],
  );

  return (
    <AuthContext.Provider value={value}>
      {children}

      <LoginModal isOpen={loginOpen} setIsOpen={setLoginOpen} focusTab={activeLoginModalTab} setFocusTab={setActiveLoginModalTab} />
    </AuthContext.Provider>
  );
};
