export const ME = `query Me {
  me {
      id
      firstName
      lastName
      email
      phoneNumber
      website
      facebookLink 
      instagramLink 
      linkedInLink 
      publicId
      publicName
      addresses {
        id
        addressName
        streetName
        city
        state
        zip
        country
        geohash
        lat
        lng
        placeId
      }
      mixes {
        id
        colorName
        hex
        brand
        part1
        part2
        part3
        part4
        tube1
        tube2
        tube3
        tube4
        mixFullPublicId
    }
     palettes {
      brand
      colors
      id
      paletteName
    }
    events { 
      eventOccurrenceId
      eventName
      eventSlug
      eventDate
      type
      maxAttendees
      attendeeNote
      numberOfAttendees
    }  
  }
}`;

export const DELETE_USER = `query DeleteUser {
  deleteUser 
}
`;

export const REGISTER = `query Register($firstName: String!, $lastName: String!, $email: String!) {
    register(firstName: $firstName, lastName: $lastName, email: $email) {
      email
      firstName
      id
      lastName
        mixes {
        id
        colorName
        hex
        brand
        part1
        part2
        part3
        part4
        tube1
        tube2
        tube3
        tube4
      }
    }
  }
`;

// A streamlined query for updatign a user's profile from contact us
export const UPDATE_PROFILE_CONTACT_US = `query UpdateUser (  $firstName: String, $lastName: String, $email: String,  $phoneNumber: String) {
  updateUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
  ) {
      email
      firstName
      id
      lastName
      phoneNumber
  }
}`;

export const UPDATE_PROFILE = `query UpdateUser ( $website: String, $firstName: String, $lastName: String, $email: String, $facebookLink: String, $instagramLink: String, $linkedInLink: String, $phoneNumber: String) {
  updateUser(
      website: $website
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      facebookLink: $facebookLink
      instagramLink: $instagramLink
      linkedInLink: $linkedInLink
  ) {
      email
      firstName
      id
      lastName
      phoneNumber
      website
      linkedInLink
      facebookLink
      instagramLink
  }
}`;

export const SEARCH_KEYWORD = `
  query SearchByKeyword($keyword: String!) {
    searchByKeyword(keyword: $keyword) {
      index
      id
      score
      fields {
        filters {
          groupName
          values
        }
        description
        path
        title
        type
        featuredImageUrl
      }
      type
    }
  }
`;

export const SAVE_MIX = `query SaveMix ($colorName: String!, $hex: String!, $part1: String, $part2: String, $part3: String, $part4: String, $tube1: String, $tube2: String, $tube3: String, $tube4: String, $brand: String!) {
  saveMix(
      colorName: $colorName
      hex: $hex
      part1: $part1
      part2: $part2
      part3: $part3
      part4: $part4
      tube1: $tube1
      tube2: $tube2
      tube3: $tube3
      tube4: $tube4
      brand: $brand
  ) {
        id
        colorName
        hex
        brand
        part1
        part2
        part3
        part4
        tube1
        tube2
        tube3
        tube4
     
  }
}
`;

export const DELETE_USER_MIX = `query DeleteMix($mixId: String!) {
    deleteMix(mixId: $mixId) 
  }
`;

export const SAVE_PALETTE = `query SavePalette ($brand: String!, $colors: [String!]!, $paletteName: String!) {
  savePalette(brand: $brand, colors: $colors, paletteName: $paletteName) {
    brand
    colors
    id
    paletteName
  }
}
`;

export const DELETE_PALETTE = `query DeletePalette($paletteId: String!) {
  deletePalette(paletteId: $paletteId) 
}`;

export const UPDATE_PALETTE = `query UpdatePalette($brand: String!, $colors: [String!]!, $id: String!, $paletteName: String!) {
  savePalette(brand: $brand, colors: $colors, id: $id, paletteName: $paletteName) {
      brand
      colors
      id
      paletteName
  }
}
`;

export const CREATE_EVENT_REGISTRATION = `query CreateEventRegistration(
 $eventDate: String!, 
 $eventOccurrenceId: String!, 
 $eventName: String!, $eventSlug: String!, $type: String!, $maxAttendees: Int!, 
 $nameUpdated: Boolean!, $firstName: String!, $lastName: String!,
 $numberOfAttendees: Int!, $attendeeNote: String, 
 $locationHtml: String
 ) {
  createEventRegistration( eventDate: $eventDate, eventOccurrenceId: $eventOccurrenceId, 
    eventName: $eventName, eventSlug: $eventSlug, type: $type, maxAttendees: $maxAttendees
    nameUpdated: $nameUpdated, firstName: $firstName, lastName: $lastName,
    numberOfAttendees: $numberOfAttendees, attendeeNote: $attendeeNote, 
    locationHtml:$locationHtml
    ) {
    attendeeNote
    eventDate
    eventOccurrenceId
    eventName
    eventSlug
    numberOfAttendees
    type
    maxAttendees
  }
}`;

export const GET_EVENT_REGISTRATIONS = `query GetEventRegistrations($eventId: String!) {
  getEventRegistrations(eventId: $eventId) 
    {
      attendeeNote
      eventDate
      eventOccurrenceId
      eventName
      eventSlug
      numberOfAttendees
      type
      maxAttendees
      firstName
      lastName
      email
   }
 }`;

export const CANCEL_EVENT_REGISTRATION = `query Query( $eventOccurrenceId: String!) {
  cancelEventRegistration(eventOccurrenceId: $eventOccurrenceId)
}`;

export const GET_USER = `query GetUser ($id: String!) {
  getUser (id: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      website
      facebookLink 
      instagramLink 
      linkedInLink 
      addresses {
        id
        addressName
        streetName
        city
        state
        zip
        country
        geohash
        lat
        lng
        placeId
      }
      mixes {
        id
        colorName
        hex
        brand
        part1
        part2
        part3
        part4
        tube1
        tube2
        tube3
        tube4
    }
     palettes {
      brand
      colors
      id
      paletteName
    }
    events { 
      eventOccurrenceId
      eventName
      eventSlug
      eventDate
      type
      maxAttendees
      attendeeNote
      numberOfAttendees
    }  
  }
}`;
