import axios from "axios";
import { ME } from "../../../graphql/queries";
import { IUser } from "../../../types/User";

export const getDbUser = async (jwt: string): Promise<IUser | null> => {
  if (!process.env.GATSBY_AWS_LAMBDA_ENDPOINT) throw new Error("Missing Environment Variable: GATSBY_AWS_LAMBDA_ENDPOINT");

  if (!jwt) {
    return null;
  }

  const { data } = await axios.post<{ data: { me: IUser } }>(
    process.env.GATSBY_AWS_LAMBDA_ENDPOINT,
    {
      query: ME,
    },
    { headers: { Authorization: jwt } },
  );

  if (!data.data) {
    return null;
  }

  return data.data.me;
};
