import axios from "axios";
import { REGISTER } from "../../../graphql/queries";
import { IUser, RegistrationData } from "../../../types/User";

export const gacRegister = async (authToken: string, registrationData: RegistrationData): Promise<IUser | null> => {
  if (!registrationData || !registrationData.email || !registrationData.firstName || !registrationData.lastName)
    throw new Error("Registration data is not defined. ");

  if (!authToken) {
    throw new Error("Couldnt get auth token. ");
  }

  if (!process.env.GATSBY_AWS_LAMBDA_ENDPOINT) throw new Error("GATSBY_AWS_LAMBDA_ENDPOINT is not defined in the environment variables. ");

  const { data } = await axios.post<{ data: { register: IUser } }>(
    process.env.GATSBY_AWS_LAMBDA_ENDPOINT,
    {
      query: REGISTER,
      variables: { ...registrationData },
    },
    { headers: { Authorization: authToken } },
  );

  if (!data.data) {
    console.error("No data returned from registration. ");
    return null;
  }

  return { ...data.data.register };
};
